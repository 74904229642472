import { Box, Button, Breadcrumbs, Text, Heading, StyledOcticon, IconButton, Dialog, TextInput, Link, Spinner, Label } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import React from "react"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { ArrowLeftIcon } from "@primer/octicons-react"
import { ExternalLinkIcon } from "@radix-ui/react-icons"
import { getSingleItportal } from "../../redux/features/portalSlice"
import { Toaster, Intent, Position } from "@blueprintjs/core";
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import assessMentYears from "../Objects/assessMentYears"
import itrFilingSecArray from "../Objects/filingSections"
import filingTypeArray from "../Objects/filingTypeArray"
import itrTypeArray from "../Objects/itrTypeArray"
const assessmentYearsData = assessMentYears();
const filingSectionData = itrFilingSecArray()
const filingTypeData = filingTypeArray()
const itrTypeData = itrTypeArray()

function ItDemandDetailsPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host
    let dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""
    const pageRoute = localStorage.getItem("pageRoute") || ""

    const [demandExp, setdemandExp] = React.useState(JSON.parse(localStorage.getItem("demandExp")))
    const [allDetails, setallDetails] = React.useState([])
    const [profileData, setprofileData] = React.useState([])

    const getSingleItportalFromDb = (props) => {

        dispatch(getSingleItportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId,
            serviceType: "PaymentExpansion"
        })).then((data) => {
            console.log("getSingleItportal", data.payload)
            setallDetails(data.payload.data)
            setprofileData(data.payload.data[0]?.playwright_itr_profile)
        })


    }




    React.useState(() => {

        console.log("demandExp", demandExp)
        getSingleItportalFromDb("")

    })

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })



    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'ITR Demand expansion - taxhero' : 'ITR Demand expansion - deski'}
                </title>
            </Helmet>

            {
                hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com"

                    ?

                    <>

                        <TaxHeroHeader />
                    </>
                    :
                    <>
                        <Header />
                        <SideNav />
                    </>
            }

            <Box
                sx={{
                    position: 'absolute',
                    top: '48px',
                    bottom: '0px',
                    left: '64px',
                    right: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    overflowX: 'hidden',

                }}>
                {/* Header section */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '37px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '60px'
                }}>
                    <Text
                        className={customTheme.H5}
                        sx={{ color: customThemVariable.secondaryTextColor }}
                    >
                        {allDetails[0]?.pan_name}
                    </Text>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '13px',
                        marginTop: '4px',
                        minHeight: '36px',
                        maxHeight: '36px',
                        height: '36px',
                        alignItems: 'center'
                    }}>
                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.primaryTextColor
                            }}
                        >
                            {profileData.length > 0 ? profileData[0]?.name : ''}
                        </Text>
                        <Text
                            className={customTheme.H4}
                            sx={{ color: customThemVariable.secondaryTextColor }}
                        >
                            {profileData.length > 0 ? profileData[0]?.pan : ''}
                        </Text>
                    </Box>


                    <Button
                        leadingIcon={() => <Box>
                            <ArrowLeftIcon />
                        </Box>
                        }
                        variant="invisible"
                        sx={{
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '14px',
                            lineHeight: '20px',
                            position: 'absolute',
                            right: '64px',
                            bottom: '0px',
                            minWidth: '95px',
                            maxWidth: '95px',
                            width: '95px',
                            height: '32px',
                            minHeight: '32px',
                            maxHeight: '32px'

                        }}
                        onClick={() => {
                            navigate('/ItDemand')
                        }}
                    >
                        Return
                    </Button>



                </Box>

                {/*  horizontal line */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    right: '61px',
                    top: '109px',
                    height: '1px',
                    background: customThemVariable.borderColor
                }}>

                </Box>


                {/* -------------------- */}


                {/* ITR Details Section 1 */}
                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '120px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '24px',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '150px',
                        maxWidth: '150px',
                        width: '150px',
                        gap: '6px',

                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Demand Info (DIN)
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >

                            {demandExp?.din}
                        </Text>
                    </Box>

                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '244px',
                        maxWidth: '244px',
                        width: '244px',
                        gap: '6px',
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Assessment year
                        </Text>
                        <Label sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#119C2B',
                            borderRadius: '6px',
                            minWidth: 'fit-content',
                            width: 'fit-content',
                            maxWidth: 'fit-content',
                            background: '#EBFFF1',
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>

                            <Text sx={{}}>
                                AY
                                {demandExp?.itrAy !== "" && demandExp?.itrAy !== undefined ?

                                    " " + assessmentYearsData.find((data) => "" + data.assment_year_cd + "" === demandExp?.itrAy)?.assment_year_desc

                                    : ""}

                            </Text>
                        </Label>

                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '178px',
                        maxWidth: '178px',
                        width: '178px',
                        gap: '6px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Current Status

                        </Text>
                        <Label sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#164e63',
                            borderRadius: '6px',
                            minWidth: 'fit-content',
                            width: 'fit-content',
                            maxWidth: 'fit-content',
                            background: '#cffafe',
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {demandExp?.currentStatus}
                        </Label>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '114px',
                        maxWidth: '114px',
                        width: '114px',
                        gap: '6px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Section Code
                        </Text>
                        <Label sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#164e63',
                            borderRadius: '6px',
                            minWidth: 'fit-content',
                            width: 'fit-content',
                            maxWidth: 'fit-content',
                            background: '#cffafe',
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {demandExp?.sectionCode}
                        </Label>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '124px',
                        maxWidth: '124px',
                        width: '124px',
                        gap: '6px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            Rectification Right
                        </Text>
                        <Label sx={{
                            position: 'relative',
                            margin: '0px 0px 0px 0px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '18px',
                            color: '#164e63',
                            borderRadius: '6px',
                            minWidth: 'fit-content',
                            width: 'fit-content',
                            maxWidth: 'fit-content',
                            background: '#cffafe',
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {demandExp?.rectificationRights}
                        </Label>
                    </Box>
                    <Box sx={{
                        height: '32px',
                        minHeight: '32px',
                        maxHeight: '32px',
                        width: '1px',
                        minWidth: '1px',
                        maxWidth: '1px',
                        background: customThemVariable.borderColor
                    }}
                    ></Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '200px',
                        maxWidth: '200px',
                        width: '200px',
                        gap: '6px'
                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Tracking Ref No
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >

                            {demandExp?.trackingRefNo}
                        </Text>
                    </Box>
                </Box>
                {/* ---------------------- */}


                {/* ITR Details Section 2 */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '182px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '24px',
                }}>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '154px',
                        maxWidth: '154px',
                        width: '154px',
                        gap: "6px",

                    }}>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Demand Raised Date
                        </Text>
                        <Text
                            className={customTheme.textnormal}
                            sx={{
                                color: customThemVariable.primaryTextColor,
                                minHeight: '20px'
                            }}
                        >
                            {new Date(demandExp?.dateOfDemandraised).toDateString().substring(3, 30)}
                        </Text>
                    </Box>





                </Box>

                {/* ---------- ITR Details Section 3 */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '252px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                }}>


                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '1100px',
                        maxWidth: '1100px',
                        width: '1100px',
                        gap: "6px",

                    }}>
                        <Text
                            className={customTheme.H3}
                            sx={{
                                color: customThemVariable.secondaryTextColor,
                            }}
                        >
                            Demand Amount Breakup

                        </Text>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '174px',
                            maxWidth: '174px',
                            width: '174px',
                            gap: "6px",

                        }}>
                            <Text
                                className={customTheme.textnormal}
                                sx={{
                                    color: customThemVariable.secondaryTextColor,
                                }}
                            >Original Demand Amount </Text>
                            <Label sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#000000',
                                borderRadius: '6px',
                                minWidth: 'fit-content',
                                width: 'fit-content',
                                maxWidth: 'fit-content',
                                background: '#fef3c7',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {demandExp?.orignalOutStDemandAmount || ""}
                            </Label>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '174px',
                            maxWidth: '174px',
                            width: '174px',
                            gap: "6px",

                        }}>
                            <Text
                                className={customTheme.textnormal}
                                sx={{
                                    color: customThemVariable.secondaryTextColor,
                                }}
                            >Interest  {demandExp?.rateOfIntrest || ""}% {demandExp?.interestUs || ""}</Text>
                            <Label sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#000000',
                                borderRadius: '6px',
                                minWidth: 'fit-content',
                                width: 'fit-content',
                                maxWidth: 'fit-content',
                                background: '#fef3c7',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {demandExp?.accruedInterest}
                            </Label>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                            gap: "6px",

                        }}>
                            <Text
                                className={customTheme.textnormal}
                                sx={{
                                    color: customThemVariable.secondaryTextColor,
                                }}
                            >Current Total Demand Amount</Text>
                            <Label sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#000000',
                                borderRadius: '6px',
                                minWidth: 'fit-content',
                                width: 'fit-content',
                                maxWidth: 'fit-content',
                                background: '#fef3c7',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {demandExp?.orignalOutStDemandAmount}
                            </Label>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                            gap: "6px",

                        }}>
                            <Text
                                className={customTheme.textnormal}
                                sx={{
                                    color: customThemVariable.secondaryTextColor,
                                }}
                            >Interest Part</Text>
                            <Label sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#000000',
                                borderRadius: '6px',
                                minWidth: 'fit-content',
                                width: 'fit-content',
                                maxWidth: 'fit-content',
                                background: '#fef3c7',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {demandExp?.accruedInterest}
                            </Label>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 'fit-content',
                            maxWidth: 'fit-content',
                            width: 'fit-content',
                            gap: "6px",

                        }}>
                            <Text
                                className={customTheme.textnormal}
                                sx={{
                                    color: customThemVariable.secondaryTextColor,
                                }}
                            >Demand Part</Text>
                            <Label sx={{
                                position: 'relative',
                                margin: '0px 0px 0px 0px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#000000',
                                borderRadius: '6px',
                                minWidth: 'fit-content',
                                width: 'fit-content',
                                maxWidth: 'fit-content',
                                background: '#fef3c7',
                                border: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {demandExp?.outstandingDemandAmountWOInterest}
                            </Label>
                        </Box>

                    </Box>


                </Box>

                {/* ----------------------- */}

                {/* Timeline section ---- */}

                <Box sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '296px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                }}>

                    {/* ITR Files Section -------------- */}



                    {/* ----------------- -------------- */}

                    <Box
                        sx={{
                            minHeight: '100px'
                        }}
                    >

                    </Box>




                </Box>


                {/* --------------------- */}







            </Box>



        </Box>


    )


}


export default ItDemandDetailsPlaywright
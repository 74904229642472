import Header from "../Header"
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { Box, Text, TextInput, Button, Heading } from "@primer/react"
import SignIntheme from "./SignIntheme"
import React from "react"
import { Auth } from 'aws-amplify';
import LoadingSpinner from "../LoadingSpinner";




function SignUp() {
    const navigate = useNavigate();

    const [showSpinner,setshowSpinner] = React.useState(false)

    const [signUpData, setsignUpData] = React.useState({
        userEmail: "",
        userPassword: "",
        firstName: "",
        lastName: ""
    })

    const [signUpError,setsignUpError]  = React.useState("")


    const [validationStatus, setValidationStatus] = React.useState({
        userEmail: "",
        userPassword: "",
        firstName: "",
        lastName: ""
    })

    const postUserPasswordInDb = (userEmail,userPassword) => {
          
       // userPassword 

       var myHeaders = new Headers();
       myHeaders.append('Content-type', 'application/json')


       const urlencoded = JSON.stringify({
           "userId": "",
           "userEmail": userEmail,
           "userPassword": userPassword,
           "updateuserPassword":userPassword
       })
 
       var requestOptions = {
           method: 'POST',
           headers: myHeaders,
           body: urlencoded,
           redirect: 'follow'
       };

       fetch(`${process.env.REACT_APP_API_URL}/postUserPassword`, requestOptions)
           .then(response => {
                console.log("password update response")
            })
           .catch((e)=>{
                console.log(e)
           })




    }

    const handleSubmitBtn = (userEmail, userPassword, firstName, lastName) => {

        if ((userEmail !== null && userPassword !== null && firstName !== null) && (userEmail !== "" && userPassword !== "" && firstName !== "")) {



            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!userEmail || regex.test(userEmail) === false) {

                setValidationStatus((prevState) => { return { ...prevState, userEmail: "error" } } )

                return false;
            } else {

                setValidationStatus({
                    userEmail: "",
                    userPassword: "",
                    firstName: "",
                    lastName: ""
                })
                setshowSpinner(true) 
                Auth.signUp(userEmail, userPassword).then(user => {

                    setshowSpinner(false)
                    localStorage.setItem("email", user.user.username)
                    localStorage.setItem("password", userPassword)
                    localStorage.setItem("firstName", firstName)
                    localStorage.setItem("lastName", lastName)
                    postUserPasswordInDb(userEmail,userPassword)
                    navigate('/emailVerification')

                }

                ).catch(error => {

                    if (error.name === "UsernameExistsException") {
                        setsignUpError("Already exists an account registered with this email address")
                        setshowSpinner(false)
                    }

                }
                )
            


            }


          

        } else {


            if (userEmail === "" && userPassword === "" && firstName === "") {

                setValidationStatus({
                    userEmail: "error",
                    userPassword: "error",
                    firstName: "error"
                })


            } else {

              

                if (userEmail === "") {



                    setValidationStatus((prevState) => {
                        return { ...prevState, userEmail: "error" }
                    }
                    )
                } else {

                    setValidationStatus((prevState) => {
                        return { ...prevState, userEmail: "" }
                    }
                    )
                }

                if (userPassword === "") {



                    setValidationStatus((prevState) => {
                        return { ...prevState, userPassword: "error" }
                    }
                    )
                } else {



                    setValidationStatus((prevState) => {
                        return { ...prevState, userPassword: "" }
                    }
                    )

                }
                if (firstName === "") {



                    setValidationStatus((prevState) => {
                        return { ...prevState, firstName: "error" }
                    }
                    )

                } else {

                    setValidationStatus((prevState) => {
                        return { ...prevState, firstName: "" }
                    }
                    )
                }

            }
        }


    }


    const handleGoogleSignUp = (props) => {

        Auth.federatedSignIn({ provider: 'Google' })
    }

    const handleLoginWithMicrosoftAd = async (props) => {

        await Auth.federatedSignIn({ provider: 'azuread' })


    }




    return (

        <Box
        position={"relative"} 
        display="flex" 
        height="100vh"
        width={"100vw"} 
        overflowY={"auto"}
        background={"#F6F8FA"} 
        >

            <Helmet>
                <title>
                    Sign up - deski
                </title>
            </Helmet>

            <Header />

            <LoadingSpinner showSpinner={showSpinner} />
            <Box sx={SignIntheme.loginMain}
              marginBottom={54}
            >
                <Box
                    sx={SignIntheme.SignUpPageBox}
                   
                >
                    <Heading sx={SignIntheme.loginPageBoxHeading}>Sign up  your free account</Heading>
                    
                    {signUpError==="" ? "": 
                    
                       <Text 
                        sx={SignIntheme.signupUserExits} 
                        color="danger.fg">
                            {signUpError}
                             
                        </Text>
                    
                    }
                    <TextInput
                        validationStatus={validationStatus.userEmail}
                        sx={SignIntheme.loginPageEmailInput}
                        name="email"
                        placeholder="Enter email"
                        type={"email"}
                        onChange={(e) =>
                            setsignUpData({ ...signUpData, userEmail: e.target.value })
                        }
                    />

                    <TextInput
                        validationStatus={validationStatus.userPassword}
                        sx={SignIntheme.loginPagePasswordInput}
                        name="password"
                        type={"password"}
                        placeholder="Enter your password"
                        onChange={(e) =>
                            setsignUpData({ ...signUpData, userPassword: e.target.value })
                        }
                    />


                    <Box
                        sx={SignIntheme.firsNameLastNameBox}
                    >
                        <TextInput
                            sx={SignIntheme.firsNameLastNameBox.firstName}
                            validationStatus={validationStatus.firstName}
                            name="firstName"
                            placeholder="First name"
                            type={"text"}
                            onChange={(e) =>
                                setsignUpData({ ...signUpData, firstName: e.target.value })
                            }
                        />
                        <TextInput
                            sx={SignIntheme.firsNameLastNameBox.lastName}
                            name="firstName"
                            placeholder="Last name"
                            type={"text"}
                            onChange={(e) =>
                                setsignUpData({ ...signUpData, lastName: e.target.value })
                            }
                        />

                    </Box>


                    <Button
                        variant="outline"
                        type="button"
                        aria-selected={true}
                        sx={SignIntheme.continueBtn}
                        onClick={() => handleSubmitBtn(signUpData.userEmail, signUpData.userPassword, signUpData.firstName, signUpData.lastName)}
                    >
                        <Text
                            as="p"
                            color="#FFFFFF"
                            sx={SignIntheme.continueBtnText}
                        >

                            Sign up
                        </Text>




                    </Button>

                    <Box
                        sx={SignIntheme.dividerBox}
                    >
                        <Box
                            sx={SignIntheme.dividerBox.firstDivider}
                        >

                        </Box>
                        <Text
                            as="P"
                            position="relative"
                            margin="0px 27px 0px 27px"
                            fontFamily="Segoe UI"
                            fontSize="12px"
                            fontWeight="400"
                            lineHeight="52px"
                            color="#586069"
                        >
                            OR
                        </Text>
                        <Box
                            sx={SignIntheme.dividerBox.secondDivider}
                        >

                        </Box>
                    </Box>

                    <Button
                        variant="default"
                        type="button"
                        leadingIcon={() => <img height="16" width="16" src="https://cdn.cdnlogo.com/logos/g/35/google-icon.svg" />}
                        sx={SignIntheme.googlBtn}
                        onClick={() => handleGoogleSignUp("")}
                    >
                        <Text
                            as="p"
                            sx={SignIntheme.googlBtn.text}

                        >
                            Continue with google
                        </Text>

                    </Button>

                    <Button
                        variant="default"
                        type="button"
                        leadingIcon={() => <img height="16" width="16" src="https://cdn.cdnlogo.com/logos/m/95/microsoft.svg" />}
                        sx={SignIntheme.microSoftBtn}
                        onClick={() => {
                            handleLoginWithMicrosoftAd("")
                        }}
                    >
                        <Text
                            as="p"
                            sx={SignIntheme.microSoftBtn.text}

                        >
                            Continue with microsoft
                        </Text>

                    </Button>


                    <Box
                        sx={SignIntheme.loginBoxBottomBox}

                    >
                        <Text
                            as="p"
                            sx={SignIntheme.loginBoxBottomBox.signUpText}
                            style={{width:119,display:'flex',justifyContent:'center'}}
                            onClick={() =>

                                navigate('/')
                            }
                        >
                            Login
                        </Text>
                        <Text
                            as="p"
                            sx={SignIntheme.loginBoxBottomBox.forgotPasswordText}
                            onClick={() =>

                                navigate('/forgetpassword')
                            }
                        >
                            Forgot password
                        </Text>


                    </Box>

                   
                </Box>
               
            </Box>

            

        </Box>

    )

}
export default SignUp

import { Box, Button, Text, Heading, Label, Select, Link } from "@primer/react"
import { Helmet } from "react-helmet"
import Header from "../../Portal/Header"
import SideNav from "../../Portal/SideNav"
import { useDispatch, useSelector } from "react-redux"
import { CheckIcon, ChevronRightIcon, DownloadIcon, FileIcon, FilterIcon } from "@primer/octicons-react";
import React from "react"
import { useNavigate } from "react-router-dom"
import { getSingleItportal } from "../../redux/features/portalSlice"
import customTheme from "../../../Theme.module.scss"
import customThemVariable from "../../../ThemeVariable.module.scss"
import { UnderlineNav } from '@primer/react/drafts'
import Papa from "papaparse";
import TaxHeroHeader from "../../Portal/TaxHeroHeader"
import RefundAmount from "./RefundAmount"
import assessMentYears from "../Objects/assessMentYears"
import itrFilingSecArray from "../Objects/filingSections"
import filingTypeArray from "../Objects/filingTypeArray"
import itrTypeArray from "../Objects/itrTypeArray"
import { Toaster, Intent, Position, ProgressBar } from "@blueprintjs/core";
import { UpdateIcon } from "@radix-ui/react-icons"
const synchingToast = Toaster.create({ position: Position.TOP })
const assessmentYearsData = assessMentYears();
const filingSectionData = itrFilingSecArray()
const filingTypeData = filingTypeArray()
const itrTypeData = itrTypeArray()

function ItReturnPlaywright(props) {

    let hostName = window.location.hostname
    let host = window.location.host

    let dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state) => state.profileData.userId)
    const userEmail = useSelector((state) => state.profileData.userEmail) || localStorage.getItem("userEmail")
    const clientId = localStorage.getItem("client_id") || ""
    const insertId = localStorage.getItem("insert_id") || ""

    const [items, setitems] = React.useState([
        { navigation: 'Return List' },
        { navigation: 'Return Files' }
    ])
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [clientName, setclientName] = React.useState("")
    const [showResetButton, setshowResetButton] = React.useState(false)
    const [allDataFeilds, setallDataFeilds] = React.useState([])
    const [showFilterSection, setshowFilterSection] = React.useState(false)
    const [returnList, setreturnList] = React.useState([])
    const [copyreturnList, setcopyreturnList] = React.useState([])
    const [progressPercentage, setprogressPercentage] = React.useState(0)
    const [isInvalidPassword, setisInvalidPassword] = React.useState(false)

    //---------Files Section -===============================
    const [returnFileList, setreturnFileList] = React.useState([])
    const [copyreturnFileList, setcopyreturnFileList] = React.useState([])
    //-------------------------------------------------------
    const [isDisabledSyncing, setisDisabledSyncing] = React.useState(false);

    //---- Filter params --------
    const [verificationArray, setverificationArray] = React.useState([{ key: "Y", value: "Complete" }, { key: "N", value: "Pending" }])
    const [itrArray, setitrArray] = React.useState(["ITR-1", "ITR-2", "ITR-3", "ITR-4", "ITR-5", "ITR-6", "ITR-7", "ITR-8", "All", "ITR-2A", "ITR-4S"])
    const [filingArray, setfilingArray] = React.useState(["Original", "Revised", "Rectification", "Defective", "Modified", "Condonation"])
    const [sortbyArray, setsortbyArray] = React.useState(["Latest Change", "Acknowledgement Date", "Verification Date", "Demand Amount", "Refund Amount", "Assessment Year"])
    const [sortbyArrayFiles, setsortbyArrayFiles] = React.useState(["Order date"])


    //---------------------------
    const [selectedVerification, setselectedVerification] = React.useState("")
    const [selectedItr, setselectedItr] = React.useState("")
    const [selectedFiling, setselectedFiling] = React.useState("")
    const [selectedSortbyArray, setselectedSortbyArray] = React.useState("")
    const [selectedAssYear, setselectedAssYear] = React.useState("")
    //----------------------------

    const getSingleItportalFromDb = (props) => {

        dispatch(getSingleItportal({
            userId: userId,
            userEmail: userEmail,
            insertId: insertId,
            serviceType: "Return"
        })).then((data) => {
            console.log("getSingleItportal", data.payload)
            setallDataFeilds(data.payload.data)
            setclientName(data?.payload?.data[0]?.pan_name)
            setreturnList(data?.payload?.data[0]?.playwright_itr_return_latest)
            setcopyreturnList(data?.payload?.data[0]?.playwright_itr_return_latest)
            setreturnFileList(data?.payload?.data[0]?.playwright_itr_returnfiles)
            setcopyreturnFileList(data?.payload?.data[0]?.playwright_itr_returnfiles)
        })



    }


    const downloadReturnExcel = (props) => {
        const csv = Papa.unparse(returnList);
        const newCsvData = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        const csvData = newCsvData;
        const link = document.createElement("a");
        link.setAttribute("href", csvData);
        link.setAttribute("download", 'returnlist.csv');
        link.click();
    }

    const resetAllFilters = (props) => {
        setselectedAssYear("")
        setselectedVerification("")
        setselectedItr("")
        setselectedFiling("")
        setselectedSortbyArray("")
        setshowResetButton(false)
        setreturnList(copyreturnList)
        setreturnFileList(copyreturnFileList)
    }

    const handleClickFilterData = (props) => {

        console.log("sortparams", {
            selectedVerification: selectedVerification,
            selectedItr: selectedItr,
            selectedFiling: selectedFiling,
            selectedSortbyArray: selectedSortbyArray
        })
        console.log("copyreturnList", copyreturnList)
        if (selectedIndex == 0) {

            const filteredArray = copyreturnList
                .filter((fData) => selectedAssYear !== "" ? fData.assmentYear?.includes(selectedAssYear) : copyreturnList)
                .filter((fData) => selectedVerification !== "" ? fData.verStatus?.includes(selectedVerification) : copyreturnList)
                .filter((fData) => selectedItr !== "" ? fData.formTypeCd?.includes(selectedItr) : copyreturnList)
                .filter((fData) => selectedFiling !== "" ? fData.filingTypeCd?.includes(selectedFiling) : copyreturnList)
                .sort((a, b) => selectedSortbyArray === "Acknowledgement Date" ? new Date(b.ackDt) - new Date(a.ackDt) : 0)
                .sort((a, b) => selectedSortbyArray === "Assessment Year" ? parseInt(b.assmentYear) - parseInt(a.assmentYear) : 0)
                .sort((a, b) => selectedSortbyArray === "Verification Date" ? new Date(b.verDt) - new Date(a.verDt) : 0)
                .sort((a, b) => {
                    if (selectedSortbyArray === "Latest Change") {

                        const dateA = new Date(b.itrPanDetlList[0].activityDt);
                        const dateB = new Date(a.itrPanDetlList[0].activityDt);
                        return dateA - dateB;

                    } else {
                        return 0
                    }
                })
                .sort((a, b) => selectedSortbyArray === "Demand Amount" ? parseInt(b.demandAmt) - parseInt(a.demandAmt) : 0)
                .sort((a, b) => selectedSortbyArray === "Refund Amount" ? parseInt(b.refundAmt) - parseInt(a.refundAmt) : 0)
            setreturnList(filteredArray)

        } else if (selectedIndex == 1) {

            const filteredArray = copyreturnFileList
                .filter((fData) => selectedAssYear !== "" ? fData.assmentYear?.includes(selectedAssYear) : copyreturnFileList)
                .sort((a, b) => {
                    if (selectedSortbyArray === "Order date" && b.intimationFileArray[0]?.orderDate !== undefined && a.intimationFileArray[0]?.orderDate !== undefined) {

                        const dateA = new Date(b.intimationFileArray[0]?.orderDate.replace("IST", "GMT+0530"));
                        const dateB = new Date(a.intimationFileArray[0]?.orderDate.replace("IST", "GMT+0530"));
                        return dateA - dateB;

                    } else {
                        return 0
                    }
                })
            setreturnFileList(filteredArray)



        }

        setshowResetButton(true)
    }

    const loadingToast = async (progressPercentage, key) => {

        synchingToast.show({
            icon: "cloud-upload",
            message: <Box sx={{ marginTop: '4px' }}>
                <ProgressBar
                    animate={true}
                    stripes={true}
                    intent={progressPercentage < 100 ? Intent.PRIMARY : Intent.SUCCESS}
                    value={progressPercentage / 100}

                />
            </Box>

            ,
            onDismiss: (didTimeoutExpire) => {
                if (!didTimeoutExpire) {

                    synchingToast.dismiss()

                }
            },
            timeout: progressPercentage < 100 ? 0 : 3000,


        }, key);

    }

    const disableSyncButton = (props) => {
        setisDisabledSyncing(true);
        setTimeout(() => {
            setisDisabledSyncing(false);
        },
            30 * 60 * 1000
        );
    };

    const handleSyncReturn = async (props) => {

        disableSyncButton("")
        //  loadingToast(10, "1")
        setprogressPercentage(1)
        setTimeout(() => {
            if (!isInvalidPassword) {
                setprogressPercentage(15)
            }
        }, 2000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(30)
            }
        }, 5000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(60)
            }
        }, 15000)
        try {

            const insertId = allDataFeilds[0]?.insert_id
            const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/syncitreturn?insertId=${insertId}&checkboxes=${JSON.stringify(["Return"])}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const profileDataResponse = responseData?.profileData || []
            const errorCode = responseData?.code
            if (errorCode === "401") {
                // synchingToast.dismiss()
                synchingToast.clear()
                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync error: Invalid username or password.",
                    intent: Intent.PRIMARY
                });
                setprogressPercentage(100)
                setisInvalidPassword(true)
            }

            if (errorCode === "200") {
                //loadingToast(100, "1")
                setprogressPercentage(100)
            }
            console.log("plinggst", responseData)
            getSingleItportalFromDb("")

        } catch (error) {


        }

    }

    const handleSyncReturnfiles = async (props) => {

        disableSyncButton("")
        // loadingToast(10, "1")
        setprogressPercentage(1)
        setTimeout(() => {
            if (!isInvalidPassword) {
                setprogressPercentage(15)
            }
        }, 2000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(30)
            }
        }, 5000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(60)
            }
        }, 15000)
        setTimeout(() => {

            if (!isInvalidPassword) {
                setprogressPercentage(85)
            }
        }, 50000)
        try {

            const insertId = allDataFeilds[0]?.insert_id
            const response = await fetch(`${process.env.REACT_APP_SCRAPSERVERURL}/syncitreturnfiles?insertId=${insertId}&checkboxes=${JSON.stringify(["Returnfiles"])}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const errorCode = responseData?.code
            if (errorCode === "401") {
                // synchingToast.dismiss()
                synchingToast.clear()
                Toaster.create({ position: Position.TOP }).show({
                    message: "Sync error: Invalid username or password.",
                    intent: Intent.PRIMARY
                });
                setprogressPercentage(100)
                setisInvalidPassword(true)
            }

            if (errorCode === "200") {
                // loadingToast(100, "1")
                setprogressPercentage(100)
            }
            console.log("plinggst", responseData)
            getSingleItportalFromDb("")

        } catch (error) {


        }

    }


    const RetrunListComponent = (props) => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '171px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    bottom: '0px',
                }}
            >


                <Box
                    sx={{
                        display: showFilterSection === true && selectedIndex == 0 ? 'flex' : 'none',
                        minHeight: 'auto',
                        marginBottom: '10px'
                    }}
                >
                    <Box sx={{
                        minHeight: '120px',
                        height: '120px',
                        maxHeight: '120px',
                        border: '1px solid #D0D7DE',
                        borderRadius: '3px',
                        background: '#FFFFFF',
                        display: 'flex',
                        flexDirection: 'column',
                        //   minWidth: '100%',
                        // minWidth: '1257px',
                        minWidth: 'fit-content',
                        paddingLeft: "94px",
                        paddingRight: "94px",
                        margin: '0px auto 0px auto'
                    }}>


                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            // justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {

                                        setselectedAssYear(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""}>AY</Select.Option>
                                    {assessmentYearsData.map((row, index) => {
                                        return (
                                            <Select.Option value={"" + row.assment_year_cd + ""}
                                                selected={selectedAssYear === "" + row.assment_year_cd + ""}
                                            >{row.assment_year_cd}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >

                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {

                                        if (e.target.value === "All") {
                                            setselectedVerification("")
                                        } else {
                                            setselectedVerification(e.target.value)
                                        }

                                    }}
                                >
                                    <Select.Option value={""}
                                        selected={selectedVerification === "" ? true : false}
                                    >Select Verification</Select.Option>
                                    <Select.Option value={"All"}
                                        selected={selectedVerification === "All" ? true : false}
                                    >All</Select.Option>
                                    {verificationArray.map((row, index) => {
                                        return (
                                            <Select.Option key={row.key} value={row.key}
                                                selected={selectedVerification === row.key}
                                            >{row.value}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {

                                        if (e.target.value === "All") {
                                            setselectedItr("")
                                        } else {
                                            setselectedItr(e.target.value)
                                        }

                                    }}

                                >
                                    <Select.Option value={""} selected={selectedItr === "" ? true : false}>Select ITR</Select.Option>
                                    <Select.Option value={"All"} selected={selectedItr === "All" ? true : false}>All</Select.Option>
                                    {itrTypeData.map((row, index) => {
                                        return (
                                            <Select.Option value={row.key}
                                                selected={selectedItr === row.key}
                                            >{row.value}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {

                                        if (e.target.value === "All") {
                                            setselectedFiling("")
                                        } else {
                                            setselectedFiling(e.target.value)
                                        }



                                    }}
                                >
                                    <Select.Option value={""} selected={selectedFiling === "" ? true : false}>Select Filling</Select.Option>
                                    <Select.Option value={"All"} selected={selectedFiling === "All" ? true : false}>All</Select.Option>
                                    {filingTypeData.map((row, index) => {
                                        return (
                                            <Select.Option value={row.key}
                                                selected={selectedFiling === row.key}
                                            >{row.display}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>
                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Select Include Tags</Select.Option>


                                </Select>
                            </Box>






                        </Box>

                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            // justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Select Exclude Tags</Select.Option>


                                </Select>
                            </Box>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {
                                        setselectedSortbyArray(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""}
                                        selected={selectedSortbyArray === "" ? true : false}
                                    >Select Sort By</Select.Option>
                                    {sortbyArray.map((row, index) => {
                                        return (
                                            <Select.Option value={row}

                                                selected={selectedSortbyArray === row}
                                            >{row}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>

                            <Box sx={{
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                            }}>
                                <Button
                                    disabled={!showResetButton}
                                    variant="outline"
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        height: '28px',
                                        minHeight: '28px',
                                        width: '190px',
                                        minWidth: '190px',
                                        maxWidth: '190px',
                                        maxHeight: '28px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    onClick={() => {
                                        resetAllFilters("")
                                    }}
                                >
                                    Reset All
                                </Button>
                            </Box>

                            <Button variant="outline" sx={{
                                background: '#0965CE',
                                color: '#ffffff',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                                onClick={() => handleClickFilterData("")}
                            >
                                Filter data
                            </Button>





                        </Box>




                    </Box>


                </Box>




                <Box sx={{
                    height: 'auto',
                    minHeight: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    {/* table header */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '40px',
                        height: '40px',
                        maxHeight: '40px',
                        borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                        background: `${customThemVariable.whiteBgColor}`
                    }}>

                        <Box sx={{
                            width: '16.1%',
                            minWidth: '16.1%',
                            maxWidth: '16.1%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                CLIENT
                            </Text>
                        </Box>

                        <Box sx={{
                            width: '11.7%',
                            minWidth: '11.7%',
                            maxWidth: '11.7%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    position: 'absolute',
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Acknowledgement INFO
                            </Text>
                        </Box>

                        <Box sx={{
                            width: '10.1%',
                            minWidth: '10.1%',
                            maxWidth: '10.1%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >

                            </Text>
                        </Box>

                        <Box sx={{
                            width: '11.7%',
                            minWidth: '11.7%',
                            maxWidth: '11.7%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Assment Year
                            </Text>
                        </Box>
                        <Box sx={{
                            width: '11.7%',
                            minWidth: '11.7%',
                            maxWidth: '11.7%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >

                            </Text>
                        </Box>

                        <Box sx={{
                            width: '7.77%',
                            minWidth: '7.77%',
                            maxWidth: '7.77%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >

                            </Text>
                        </Box>

                        <Box sx={{
                            width: '7.77%',
                            minWidth: '7.77%',
                            maxWidth: '7.77%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >

                            </Text>
                        </Box>
                        <Box sx={{
                            width: '7.77%',
                            minWidth: '7.77%',
                            maxWidth: '7.77%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',

                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >

                            </Text>
                        </Box>

                        <Box sx={{
                            width: '15.4%',
                            minWidth: '15.4%',
                            maxWidth: '15.4%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Refund Amount
                            </Text>
                        </Box>


                    </Box>
                    {/* ------------ */}

                    {returnList?.map((row, index) => {

                        return (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                minHeight: '40px',
                                height: '40px',
                                maxHeight: '40px',
                                borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                background: `${customThemVariable.whiteBgColor}`,
                                ":hover": {
                                    background: 'rgba(234, 238, 242, 0.5)'
                                },
                                cursor: 'pointer'
                            }}
                                onClick={() => {
                                    // localStorage.setItem("itr_id", row.itr_id)
                                    // localStorage.setItem("ackNumber", row?.acknowledgementNo)
                                    localStorage.setItem("pageRoute", "")
                                    localStorage.setItem("expData", JSON.stringify(row))
                                    localStorage.setItem("expFileData", JSON.stringify(returnFileList.filter((fData) => fData?.ackNumber === row?.ackNum)))
                                    navigate('/Itrexpansion')
                                }}
                            >

                                <Box sx={{
                                    width: '16.1%',
                                    minWidth: '16.1%',
                                    maxWidth: '16.1%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >


                                        {clientName}

                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '11.7%',
                                    minWidth: '11.7%',
                                    maxWidth: '11.7%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >

                                        {row?.itrPanDetlList?.length > 0 && row?.itrPanDetlList[0].activityDt !== undefined ?
                                            new Date(row?.itrPanDetlList[0].activityDt).toDateString().substring(3, 100)
                                            : ""

                                        }
                                        {/* {new Date(row?.ackDt).toDateString().substring(3, 100)} */}




                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '10.1%',
                                    minWidth: '10.1%',
                                    maxWidth: '10.1%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >

                                        {row?.itrPanDetlList?.length > 0 && row?.itrPanDetlList[0].statusDesc !== undefined ?
                                            row?.itrPanDetlList[0].statusDesc.substring(0, 11)
                                            : ""}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '11.7%',
                                    minWidth: '11.7%',
                                    maxWidth: '11.7%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>



                                    <Label sx={{
                                        marginLeft: '16px',
                                        fontFamily: customThemVariable.primartFont,
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: customThemVariable.labelTextColorgreen,
                                        borderRadius: '6px',
                                        minHeight: '24px',
                                        maxHeight: '24px',
                                        height: '24px',
                                        background: customThemVariable.labelBgTertiaryColor,
                                        border: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                color: customThemVariable.icongreenColor,
                                                marginLeft: '0px'
                                            }}>
                                            <CheckIcon size={19} />
                                        </Box>

                                        <Text sx={{ display: 'flex', marginLeft: '4px' }}>
                                            AY
                                            {row?.assmentYear !== "" && row?.assmentYear !== undefined ?

                                                " " + assessmentYearsData.find((data) => "" + data.assment_year_cd + "" === row?.assmentYear)?.assment_year_desc

                                                : ""}
                                        </Text>




                                    </Label>

                                </Box>
                                <Box sx={{
                                    width: '11.7%',
                                    minWidth: '11.7%',
                                    maxWidth: '11.7%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >
                                        {/* {new Date(row?.timeLineData[0]?.date).toDateString().substring(3, 20)} */}
                                        {row?.ackDt !== null && row?.ackDt !== undefined ?
                                            new Date(row?.ackDt).toDateString().substring(3, 100)
                                            : ""}
                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '7.77%',
                                    minWidth: '7.77%',
                                    maxWidth: '7.77%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >
                                        {row?.filingTypeCd !== "" && row?.filingTypeCd !== undefined ?

                                            " " + filingTypeData.find((data) => data.key === row?.filingTypeCd)?.display

                                            : ""}

                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '7.77%',
                                    minWidth: '7.77%',
                                    maxWidth: '7.77%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >
                                        {row?.incmTaxSecCd !== "" && row?.incmTaxSecCd !== undefined ?

                                            " " + filingSectionData.find((data) => data.key === row?.incmTaxSecCd)?.value !== undefined ? filingSectionData.find((data) => data.key === row?.incmTaxSecCd)?.value : ""

                                            : ""}

                                    </Text>
                                </Box>
                                <Box sx={{
                                    width: '7.77%',
                                    minWidth: '7.77%',
                                    maxWidth: '7.77%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >

                                        {row?.formTypeCd !== "" && row?.formTypeCd !== undefined ?

                                            " " + itrTypeData.find((data) => data.key === row?.formTypeCd).value

                                            : ""}



                                    </Text>
                                </Box>

                                <Box sx={{
                                    width: '15.4%',
                                    minWidth: '15.4%',
                                    maxWidth: '15.4%',
                                    minHeight: '40px',
                                    maxHeight: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Text
                                        className={customTheme.textnormal}
                                        sx={{
                                            marginLeft: '16px',
                                            color: `${customThemVariable.darkgreysecond}`
                                        }}
                                    >
                                        {row?.refundAmt > 0 && row?.refundAmt !== undefined
                                            ? row?.refundAmt : ""

                                        }

                                    </Text>
                                </Box>


                            </Box>
                        )

                    })}




                </Box>

                <Box sx={{
                    minHeight: '116px'
                }}>

                </Box>




            </Box>
        )
    }

    const RetrunFileComponent = (props) => {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    left: '26px',
                    top: '171px',
                    right: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    bottom: '0px',
                }}
            >

                <Box
                    sx={{
                        display: showFilterSection === true && selectedIndex == 1 ? 'flex' : 'none',
                        minHeight: 'auto',
                        marginBottom: '10px'
                    }}
                >
                    <Box sx={{
                        minHeight: '120px',
                        height: '120px',
                        maxHeight: '120px',
                        border: '1px solid #D0D7DE',
                        borderRadius: '3px',
                        background: '#FFFFFF',
                        display: 'flex',
                        flexDirection: 'column',
                        //   minWidth: '100%',
                        //  minWidth: '1257px',
                        minWidth: 'fit-content',
                        paddingLeft: "298px",
                        paddingRight: "298px",
                        margin: '0px auto 0px auto'
                    }}>

                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {

                                        setselectedAssYear(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""}>AY</Select.Option>
                                    {assessmentYearsData.map((row, index) => {
                                        return (
                                            <Select.Option value={"" + row.assment_year_cd + ""}
                                                selected={selectedAssYear === "" + row.assment_year_cd + ""}
                                            >{row.assment_year_cd}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Box>
                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Select Include Tags</Select.Option>


                                </Select>
                            </Box>
                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => { }}

                                >
                                    <Select.Option value={""}>Select Exclude Tags</Select.Option>


                                </Select>
                            </Box>





                        </Box>

                        <Box sx={{
                            marginTop: '22px',
                            minHeight: '28px',
                            maxHeight: '28px',
                            height: '28px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            gap: '15px'
                        }}>

                            <Box
                                className="selectSpana"
                                sx={{
                                    minHeight: '28px',
                                    maxHeight: '28px',
                                    height: '28px',
                                    minWidth: '190px',
                                    maxWidth: '190px',
                                    width: '190px',
                                    fontFamily: 'Segoe UI',
                                    fontSize: '12px',
                                    fontWeight: '400'

                                }}  >


                                <Select
                                    block
                                    aria-label="Compliance type"
                                    style={{
                                        fontFamily: 'Segoe UI',
                                        fontSize: '12px',
                                        fontWeight: '400'

                                    }}
                                    onChange={(e) => {
                                        setselectedSortbyArray(e.target.value)
                                    }}

                                >
                                    <Select.Option value={""}
                                        selected={selectedSortbyArray === "" ? true : false}
                                    >Select Sort By</Select.Option>
                                    {sortbyArrayFiles.map((row, index) => {
                                        return (
                                            <Select.Option value={row}

                                                selected={selectedSortbyArray === row}
                                            >{row}</Select.Option>
                                        )
                                    })}

                                </Select>
                            </Box>

                            <Box sx={{
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                            }}>
                                <Button
                                    disabled={!showResetButton}
                                    variant="outline"
                                    sx={{
                                        fontFamily: 'Segoe UI',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        height: '28px',
                                        minHeight: '28px',
                                        width: '190px',
                                        minWidth: '190px',
                                        maxWidth: '190px',
                                        maxHeight: '28px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    onClick={() => {
                                        resetAllFilters("")
                                    }}
                                >
                                    Reset All
                                </Button>
                            </Box>

                            <Button variant="outline" sx={{
                                background: '#0965CE',
                                color: '#ffffff',
                                fontFamily: 'Segoe UI',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                fontSize: '12px',
                                lineHeight: '18px',
                                height: '28px',
                                minHeight: '28px',
                                width: '190px',
                                minWidth: '190px',
                                maxWidth: '190px',
                                maxHeight: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                                onClick={() => handleClickFilterData("")}
                            >
                                Filter data
                            </Button>





                        </Box>




                    </Box>


                </Box>





                <Box sx={{
                    height: 'auto',
                    minHeight: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    {/* table header */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        minHeight: '40px',
                        height: '40px',
                        maxHeight: '40px',
                        borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                        background: `${customThemVariable.whiteBgColor}`
                    }}>

                        <Box sx={{
                            width: '16.1%',
                            minWidth: '16.1%',
                            maxWidth: '16.1%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                CLIENT
                            </Text>
                        </Box>

                        <Box sx={{
                            width: '16.1%',
                            minWidth: '16.1%',
                            maxWidth: '16.1%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',

                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Acknowledgement INFO
                            </Text>
                        </Box>
                        <Box sx={{
                            width: '11.7%',
                            minWidth: '11.7%',
                            maxWidth: '11.7%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    position: 'absolute',
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Assment Year
                            </Text>
                        </Box>

                        <Box sx={{
                            width: '35.7%',
                            minWidth: '35.7%',
                            maxWidth: '35.7%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',

                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    position: 'absolute',
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                File Type
                            </Text>
                        </Box>

                        <Box sx={{
                            width: '10.1%',
                            minWidth: '10.1%',
                            maxWidth: '10.1%',
                            minHeight: '40px',
                            maxHeight: '40px',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',

                        }}>
                            <Text
                                className={customTheme.H6}
                                sx={{
                                    marginLeft: '16px',
                                    textTransform: 'uppercase',
                                    color: `${customThemVariable.midgreyText}`
                                }}
                            >
                                Date
                            </Text>
                        </Box>


                    </Box>
                    {/* ------------ */}

                    {returnFileList?.map((row, index) => {
                        return (
                            <>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    minHeight: 'auto',
                                    height: 'auto',
                                    maxHeight: 'auto',
                                    paddingBottom: '4px',
                                    paddingTop: '4px',
                                    borderBottom: `1px solid ${customThemVariable.borderColorTertiary}`,
                                    background: `${customThemVariable.whiteBgColor}`,
                                    ":hover": {
                                        background: 'rgba(234, 238, 242, 0.5)'
                                    },
                                    cursor: 'pointer'
                                }}
                                >

                                    <Box sx={{
                                        width: '16.1%',
                                        minWidth: '16.1%',
                                        maxWidth: '16.1%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                        onClick={() => {
                                            // localStorage.setItem("itr_id", row?.itr_id)
                                            // localStorage.setItem("ackNumber", row?.ackNumber)
                                            // localStorage.setItem("pageRoute", "")
                                            // navigate('/Itrexpansion')
                                        }}

                                    >
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >


                                            {clientName}

                                        </Text>
                                    </Box>

                                    <Box sx={{
                                        width: '16.1%',
                                        minWidth: '16.1%',
                                        maxWidth: '16.1%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',

                                    }}>
                                        <Text
                                            className={customTheme.textnormal}
                                            sx={{
                                                marginLeft: '16px',
                                                color: `${customThemVariable.darkgreysecond}`
                                            }}
                                        >


                                            {row?.ackNumber}

                                        </Text>
                                    </Box>

                                    <Box sx={{
                                        width: '11.7%',
                                        minWidth: '11.7%',
                                        maxWidth: '11.7%',
                                        minHeight: '40px',
                                        maxHeight: '40px',
                                        height: '40px',
                                        display: 'flex',
                                        alignItems: 'center',

                                    }}>



                                        <Label sx={{
                                            marginLeft: '16px',
                                            fontFamily: customThemVariable.primartFont,
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: customThemVariable.labelTextColorgreen,
                                            borderRadius: '6px',
                                            minHeight: '24px',
                                            maxHeight: '24px',
                                            height: '24px',
                                            background: customThemVariable.labelBgTertiaryColor,
                                            border: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    color: customThemVariable.icongreenColor,
                                                    marginLeft: '0px'
                                                }}>
                                                <CheckIcon size={19} />
                                            </Box>

                                            <Text sx={{ display: 'flex', marginLeft: '4px' }}>


                                                {row?.assmentYear !== "" && row?.assmentYear !== undefined ?

                                                    " " + assessmentYearsData.find((data) => "" + data.assment_year_cd + "" === row?.assmentYear)?.assment_year_desc

                                                    : ""}

                                            </Text>




                                        </Label>

                                    </Box>

                                    <Box sx={{
                                        width: '35.7%',
                                        minWidth: '35.7%',
                                        maxWidth: '35.7%',
                                        minHeight: 'auto',
                                        maxHeight: 'auto',
                                        height: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingLeft: '16px',
                                        gap: '8px'
                                    }}>
                                        <Link
                                            className={customTheme.textnormal}
                                            sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                position: 'relative',
                                                margin: '0px 12px 0px 0px',
                                            }}
                                            href="#"
                                            onClick={() => {
                                                window.open(row?.formFileUrl)
                                            }}
                                        >Form</Link>


                                        <Link
                                            className={customTheme.textnormal}
                                            sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                position: 'relative',
                                                margin: '0px 12px 0px 0px',
                                            }}
                                            href="#"
                                            onClick={() => {
                                                window.open(row?.receiptFileUrl)
                                            }}
                                        >Receipt</Link>


                                        <Link
                                            className={customTheme.textnormal}
                                            sx={{
                                                fontFamily: 'Segoe UI',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                                position: 'relative',
                                                margin: '0px 12px 0px 0px',
                                            }}
                                            href="#"
                                            onClick={() => {
                                                window.open(row?.jsonFileUrl)
                                            }}
                                        >Json</Link>



                                        {row?.intimationFileArray?.length > 0 ?

                                            row?.intimationFileArray.map((row, index) => {
                                                return (

                                                    <Link
                                                        className={customTheme.textnormal}
                                                        sx={{
                                                            fontFamily: 'Segoe UI',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            lineHeight: '24px',
                                                            position: 'relative',
                                                            margin: '0px 12px 0px 0px',
                                                        }}
                                                        href="#"
                                                        onClick={() => {
                                                            window.open(row?.orderUrl)
                                                        }}
                                                    >{row?.orderUrlFile}</Link>

                                                )
                                            })

                                            : ""}

                                    </Box>


                                    <Box sx={{
                                        width: '11.7%',
                                        minWidth: '11.7%',
                                        maxWidth: '11.7%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px',
                                        justifyContent: 'flex-end'
                                    }}>


                                        {row?.intimationFileArray?.length > 0 ?

                                            row?.intimationFileArray.map((row2, index2) => {
                                                return (

                                                    <>
                                                        <Text
                                                            className={customTheme.textnormal}
                                                            sx={{

                                                                color: `${customThemVariable.darkgreysecond}`
                                                            }}
                                                        >
                                                            {
                                                                new Date(row2?.orderDate?.replace("IST", "GMT+0530")).toLocaleDateString("en-GB", {
                                                                    day: "2-digit",
                                                                    month: "short",
                                                                    year: "numeric"
                                                                })
                                                            }

                                                        </Text>
                                                    </>



                                                )
                                            })

                                            : ""}


                                    </Box>






                                </Box>
                            </>
                        )
                    })}

                </Box>

                <Box sx={{
                    minHeight: '116px'
                }}>

                </Box>




            </Box>
        )
    }

    React.useState(() => {

        getSingleItportalFromDb("")

    })

    React.useEffect(() => {

        if (progressPercentage > 0 && !isInvalidPassword) {
            loadingToast(progressPercentage, "1")
        }

    }, [progressPercentage])

    React.useState(() => {

        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "ibtrxlrmkf");

    })



    return (
        <Box
            position={"relative"}
            display="flex"
            background={"#F6F8FA"}
            height="100vh"
            width={"100vw"}
            overflowY={"auto"}

        >
            <Helmet>
                <title>
                    {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? 'IT Return details - taxhero' : 'IT Return details - deski'}
                </title>
            </Helmet>


            {hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ?

                <><TaxHeroHeader /></>
                :
                <><Header /><SideNav /></>
            }




            <Box sx={{
                position: 'absolute',
                top: '48px',
                bottom: '0px',
                left: '64px',
                right: '0px',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'scroll',
                overflowX: 'hidden',
            }}>


                {/* fixed header */}

                <Box sx={{
                    top: '48px',
                    position: 'fixed',
                    left: hostName === "www.app.taxherohq.com" || hostName === "www.get.taxherohq.com" ? '0px' : '64px',
                    right: '0px',
                    minHeight: '48px',
                    maxHeight: '48px',
                    height: '48px',
                    background: customThemVariable.whiteBgColor,
                    display: 'flex',
                    borderBottom: '1px solid #E5E9EB',
                    zIndex: '1',
                    alignItems: "center"
                }}>

                    <Text sx={{
                        marginTop: '12px',
                        marginLeft: '32px',
                        marginBottom: '15px',
                        color: customThemVariable.textgray,
                        fontSize: '14px',
                        fontFamily: customThemVariable.primartFont,
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        cursor: 'pointer'
                    }}
                        onClick={() => {
                            hostName === "www.app.taxherohq.com"
                                ||
                                hostName === "www.get.taxherohq.com"
                                ?
                                navigate('/taxheroportal')
                                :
                                navigate('/portal')
                        }}
                    >
                        Home
                    </Text>

                    <Box sx={{
                        marginLeft: '6px',
                        marginTop: '14px',
                        marginBottom: '18px',
                        color: '#B0BABF'
                    }}>

                        <ChevronRightIcon />
                    </Box>

                    <Text
                        sx={{
                            marginTop: '12px',
                            marginLeft: '6px',
                            marginBottom: '15px',
                            color: customThemVariable.textgraydark,
                            fontSize: '14px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            navigate('/itpanlist')
                        }}
                    >
                        IT
                    </Text>

                    <Box sx={{
                        marginLeft: '6px',
                        marginTop: '14px',
                        marginBottom: '18px',
                        color: '#B0BABF'
                    }}>

                        <ChevronRightIcon />
                    </Box>

                    <Text
                        sx={{
                            marginTop: '12px',
                            marginLeft: '6px',
                            marginBottom: '15px',
                            color: customThemVariable.textgraydark,
                            fontSize: '14px',
                            fontFamily: customThemVariable.primartFont,
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                            cursor: 'pointer'
                        }}
                        onClick={() => { }}
                    >
                        {clientName.substring(0, 100)}

                    </Text>


                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', position: 'absolute', right: '160px' }}>
                        <Button
                            id="gstsyncBtn"
                            disabled={isDisabledSyncing}
                            variant="invisible"
                            leadingIcon={() => <Box sx={{ display: "flex" }}><UpdateIcon /></Box>}
                            sx={{
                                padding: '4px 8px 4px 8px',
                                display: selectedIndex == 0 ? 'flex' : "none",
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontFamily: customThemVariable.primartFont,
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            onClick={() => {
                                handleSyncReturn("")
                            }}
                        >
                            Sync data
                        </Button>
                        <Button
                            id="gstsyncBtn"
                            disabled={isDisabledSyncing}
                            variant="invisible"
                            leadingIcon={() => <Box sx={{ display: "flex" }}><UpdateIcon /></Box>}
                            sx={{
                                padding: '4px 8px 4px 8px',
                                display: selectedIndex == 1 ? 'flex' : 'none',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontFamily: customThemVariable.primartFont,
                                fontWeight: '600',
                                fontSize: '14px',
                                lineHeight: '20px',
                            }}
                            onClick={() => {
                                handleSyncReturnfiles("")
                            }}
                        >
                            Sync data
                        </Button>
                    </Box>



                    <Box sx={{
                        position: 'absolute',
                        // top: '5px',
                        right: '61px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '3px'
                    }}>
                        <Button

                            leadingIcon={() => <Box><FilterIcon /></Box>}
                            sx={{
                                fontFamily: customThemVariable.primartFont,
                                fontSize: '14px',
                                fontStyle: customThemVariable.fontStyleNormal,
                                fontWeight: 600,
                                lineHeight: '20px',
                                paddingLeft: '13px',
                                paddingRight: '13px',
                                borderRadius: '6px',
                                color: customThemVariable.textgraydark,
                                border: 'none',
                                background: 'transparent'
                            }}
                            onClick={() => {
                                setshowFilterSection(!showFilterSection)
                            }}
                        >

                        </Button>
                        <Button
                            leadingIcon={() => <Box><DownloadIcon /></Box>}

                            sx={{
                                fontFamily: customThemVariable.primartFont,
                                fontSize: '14px',
                                fontStyle: customThemVariable.fontStyleNormal,
                                fontWeight: 600,
                                lineHeight: '20px',
                                paddingLeft: '13px',
                                paddingRight: '13px',
                                borderRadius: '6px',
                                color: customThemVariable.textgraydark,
                                border: 'none',
                                background: 'transparent'
                            }}
                            onClick={() => {
                                downloadReturnExcel("")
                            }}
                        >

                        </Button>



                    </Box>


                </Box>

                {/*  */}


                <Heading
                    className={customTheme.H1}
                    sx={{
                        position: 'absolute',
                        fontSize: '24px',
                        left: '26px',
                        top: '58px'
                    }}
                >
                    Return
                </Heading>

                <UnderlineNav
                    key={"" + Math.random(100, 10000) + ""}
                    aria-label="Repository"
                    sx={{
                        position: 'absolute',
                        left: '26px',
                        top: '116px',
                        bottom: '0px',
                        right: '61px',
                        minHeight: 40,
                        maxHeight: 40,
                        height: 40,
                        fontFamily: customThemVariable.primartFont,
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: customThemVariable.$textgraydark,
                        paddingLeft: '0px',
                    }} >
                    {items.map((item, index) => (

                        <UnderlineNav.Item
                            key={item.navigation}
                            aria-current={index === selectedIndex ? 'page' : undefined}
                            onSelect={e => {
                                setSelectedIndex(index)
                                resetAllFilters("")
                                setshowFilterSection(false)
                                e.preventDefault()
                            }}
                            selected={index === selectedIndex}
                        >
                            {item.navigation}
                        </UnderlineNav.Item>

                    ))}
                </UnderlineNav>

                {selectedIndex == 0 ? <RetrunListComponent />
                    :
                    selectedIndex == 1 ? <RetrunFileComponent />
                        : ""
                }




            </Box>

        </Box>
    )

}

export default ItReturnPlaywright